<template>
    <el-container class="api" v-title data-title="接口文档">
        <css href="//at.alicdn.com/t/font_1896021_aem2yk1526s.css"/>
        <el-header class="api-header">
            <el-col :span="24">
                <h1>{{project.name}}接口文档
                    <el-button v-if="false" type="text" icon="el-icon-refresh" @click="loadApi">刷新</el-button>
                </h1>
            </el-col>
        </el-header>
        <el-container style="overflow: auto">
            <el-aside width="300px" class="api-aside">
                <el-menu
                        :unique-opened="true"
                        default-active="1-1"
                        class="el-menu-vertical-demo"
                        :default-openeds="openeds">
                    <el-submenu v-for="(item,key) in modules" :key="key" :index="(key+1).toString()">
                        <template slot="title">
                            <i class="el-icon-ali-dian"></i>
                            <span>{{item.name}}</span>
                        </template>
                        <el-menu-item-group>
                            <el-menu-item v-for="(api,akey) in item.api" :key="akey"
                                          :index="(key+1).toString()+'-'+(akey+1).toString()" @click="detail(api)">
                                {{api.title}}
                            </el-menu-item>
                        </el-menu-item-group>
                    </el-submenu>
                </el-menu>
            </el-aside>
            <el-main v-if="!api.id">
                <el-alert
                        title="请选择接口"
                        type="info"
                        effect="dark"
                        :closable="false">
                </el-alert>
            </el-main>
            <el-main v-if="api.id">
                <el-alert
                        :title="api.title+' 最近更新: '+ api.updated +' by '+api.user+' 测试环境网关:'+project.gateway_dev+' 生产环境网关:'+project.gateway_production"
                        type="info"
                        effect="dark"
                        close-text="刷新"
                        :closable="false">
                </el-alert>
                <div class="mt-20">
                    <span>请求说明</span>
                    <el-divider></el-divider>
                    <el-row>
                        <p>接口地址: </p>
                        <el-col :span="24">
                            <div class="description mt-15">
                                <el-input v-model="api.url" placeholder="接口地址" size="medium"></el-input>
                            </div>
                        </el-col>
                    </el-row>
                    <el-row class="mt-20">
                        <span>请求类型:</span>
                        <el-col :span="24">
                            <div class="description mt-15">
                                <el-radio v-model="api.request_type" label="1">GET</el-radio>
                                <el-radio v-model="api.request_type" label="2">POST</el-radio>
                            </div>
                        </el-col>
                    </el-row>
                    <el-row class="mt-20">
                        <span>接口说明:</span>
                        <el-col :span="24">
                            <div class="description mt-15" v-html="api.tips">
                            </div>
                        </el-col>
                    </el-row>
                    <el-tabs v-model="activeParamsTab" class="mt-15">
                        <el-tab-pane label="业务参数" name="biz">
                            <el-col :span="24">
                                <el-alert
                                        title="调试值支持变量: {rand}:32位随机字符串;{timestamp}:当前时间戳;{appid}:调试appid;{sign}:调试自动签名"
                                        show-icon
                                        :closable="false"
                                        type="info">
                                </el-alert>
                            </el-col>
                            <el-row>
                                <el-col :span="24" class="mt-10">
                                    <el-table
                                            :data="api.request_params"
                                            border
                                            style="width: 100%"
                                            row-key="key"
                                            default-expand-all>
                                        <el-table-column
                                                prop="key"
                                                label="参数名"
                                                width="200">
                                            <template slot-scope="{row}">
                                                <el-input v-model="row.key" placeholder="参数名称"
                                                          size="medium" v-if="row.temp==1"></el-input>
                                                <span v-if="row.temp!=1">{{row.key}}</span>
                                            </template>
                                        </el-table-column>
                                        <el-table-column
                                                prop="type"
                                                label="类型"
                                                width="150">
                                        </el-table-column>
                                        <el-table-column
                                                prop="required"
                                                label="是否必须"
                                                width="100">
                                            <template slot-scope="scope">
                                                <el-tag type="success" v-if="scope.row.required==1">是</el-tag>
                                                <el-tag type="info" v-if="scope.row.required==0">否</el-tag>
                                            </template>
                                        </el-table-column>
                                        <el-table-column
                                                prop="description"
                                                label="参数说明"
                                        >
                                        </el-table-column>
                                        <el-table-column
                                                prop="value"
                                                label="调试值"
                                                width="350">
                                            <template slot="header">
                                                <span>调试值</span>
                                                <el-link type="primary" @click="addRequestParams" class="ml-10">
                                                    +增加
                                                </el-link>
                                                <el-button type="info" class="fr" @click="setDebugParamsValue"
                                                           v-if="project.id==1">赋值
                                                </el-button>
                                            </template>
                                            <template slot-scope="scope">
                                                <el-row :gutter="10">
                                                    <el-col :span="15" v-if="scope.row.type==='DateTime'">
                                                        <el-date-picker
                                                                v-model="scope.row.value"
                                                                type="datetime"
                                                                format="yyyy-MM-dd HH:mm:ss"
                                                                value-format="yyyy-MM-dd HH:mm:ss"
                                                                placeholder="选择日期时间">
                                                        </el-date-picker>
                                                    </el-col>
                                                    <el-col :span="15" v-if="scope.row.type==='Timestamp'">
                                                        <el-date-picker
                                                                v-model="scope.row.value"
                                                                type="datetime"
                                                                format="yyyy-MM-dd HH:mm:ss"
                                                                value-format="timestamp"
                                                                placeholder="选择日期时间">
                                                        </el-date-picker>
                                                    </el-col>
                                                    <el-col :span="15" v-else-if="scope.row.type==='Array'">
                                                        <el-input v-model="scope.row.value[0]" placeholder="调试值"
                                                                  size="medium"></el-input>
                                                        <el-input v-model="scope.row.value[1]" placeholder="调试值"
                                                                  size="medium" class="mt-10"></el-input>
                                                    </el-col>
                                                    <el-col :span="15" v-else-if="scope.row.type==='Image'">
                                                        <image-uploader v-model="scope.row.value" dt="id"/>
                                                    </el-col>
                                                    <el-col :span="15" v-else-if="scope.row.type==='ImageUrl'">
                                                        <image-uploader v-model="scope.row.value" dt="url"/>
                                                    </el-col>
                                                    <el-col :span="15" v-else-if="scope.row.type==='File'">
                                                        <el-upload
                                                                style="width: 50px"
                                                                :action="api.url"
                                                                :show-file-list="false"
                                                                :on-progress="onProgress"
                                                                :on-change="onChange"
                                                                :on-success="onSuccess"
                                                                :before-upload="beforeUpload">
                                                            <el-link type="primary">上传</el-link>
                                                        </el-upload>
                                                    </el-col>
                                                    <el-col :span="15" v-else-if="scope.row.type==='ImageArray'">
                                                        <image-uploader v-model="scope.row.value[0]" dt="id"/>
                                                        <image-uploader v-model="scope.row.value[1]" dt="id"/>
                                                    </el-col>
                                                    <el-col :span="15" v-else-if="scope.row.type==='ImageUrlArray'">
                                                        <image-uploader v-model="scope.row.value[0]" dt="url"/>
                                                        <image-uploader v-model="scope.row.value[1]" dt="url"/>
                                                    </el-col>
                                                    <span v-else-if="scope.row.type==='Object'">--</span>
                                                    <el-col :span="15" v-else>
                                                        <el-input v-model="scope.row.value" placeholder="调试值"
                                                                  size="medium"></el-input>
                                                    </el-col>
                                                    <el-col :span="5" v-if="scope.row.temp==1">
                                                        <el-link type="primary"
                                                                 @click="removeRequestParams(scope.$index)">移除
                                                        </el-link>
                                                    </el-col>
                                                </el-row>
                                            </template>
                                        </el-table-column>
                                    </el-table>
                                </el-col>
                            </el-row>
                        </el-tab-pane>
                        <el-tab-pane label="基础参数" name="base" v-if="project.debug_setting">
                            <el-row>
                                <el-col :span="24">
                                    <el-alert
                                            title="基础参数是为了方便测试所设,测试环境调试时会自动生成相关参数值并自动完成签名,生产环境请参考文档进行相关基础参数拼接以及签名赋值,调试值支持变量: {rand}:32位随机字符串;{timestamp}:当前时间戳;{appid}:调试appid;{sign}:调试自动签名"
                                            show-icon
                                            :closable="false"
                                            type="info">
                                    </el-alert>
                                </el-col>
                                <el-col :span="24" class="mt-10">
                                    <el-table
                                            :data="project.debug_setting"
                                            border
                                            style="width: 100%">
                                        <el-table-column
                                                prop="key"
                                                label="参数名"
                                                width="180">
                                            <template slot-scope="{row}">
                                                <el-input v-model="row.key" placeholder="参数名称"
                                                          size="medium"></el-input>
                                            </template>
                                        </el-table-column>
                                        <el-table-column
                                                prop="value"
                                                label="参数值">
                                            <template slot="header">
                                                <span>参数值</span>
                                                <el-link type="primary" @click="addProjectParams" class="ml-10">
                                                    +增加
                                                </el-link>
                                            </template>
                                            <template slot-scope="scope">
                                                <el-row :gutter="10">
                                                    <el-col :span="10">
                                                        <el-input v-model="scope.row.value" placeholder="参数值"
                                                                  size="medium"></el-input>
                                                    </el-col>
                                                    <el-col :span="5" v-if="scope.row.temp==1">
                                                        <el-link type="primary"
                                                                 @click="removeProjectParams(scope.$index)">移除
                                                        </el-link>
                                                    </el-col>
                                                </el-row>

                                            </template>
                                        </el-table-column>
                                    </el-table>
                                </el-col>
                            </el-row>
                        </el-tab-pane>
                        <el-tab-pane label="签名参数" name="sign" v-if="project.debug_setting">
                            <el-row :gutter="20">
                                <el-col :span="5">
                                    <el-input placeholder="调试appid" size="small" v-model="project.debug_appid">
                                        <template slot="prepend">APPID</template>
                                    </el-input>
                                </el-col>
                                <el-col :span="8">
                                    <el-input placeholder="调试appkey" size="small"
                                              v-model="project.debug_appkey">
                                        <template slot="prepend">APPKEY</template>
                                    </el-input>
                                </el-col>
                                <el-col :span="8">
                                    <el-input placeholder="调试用户token" size="small"
                                              v-model="project.debug_token">
                                        <template slot="prepend">TOKEN</template>
                                    </el-input>
                                </el-col>
                            </el-row>
                        </el-tab-pane>
                    </el-tabs>
                    <el-row>
                        <el-col :span="24" class="mt-10">
                            <el-button type="primary" class="fr" @click="debug()" :loading="isLoading">调试
                            </el-button>
                        </el-col>
                    </el-row>
                    <el-tabs v-model="activeResultTab" class="mt-15"
                             v-if="requestJsonData || responseJsonData">
                        <el-tab-pane label="响应数据" name="request">
                            <el-col :span="24" v-if="responseJsonData">
                                <div class="description">
                                    {{debug_url}}
                                </div>
                                <div class="description">
                                    <json-viewer :value="responseJsonData" :expand-depth=10 copyable
                                                 expanded></json-viewer>
                                </div>
                            </el-col>
                        </el-tab-pane>
                        <el-tab-pane label="请求数据" name="response">
                            <el-col :span="24" v-if="requestJsonData">
                                <div class="description">
                                    {{debug_url}}
                                </div>
                                <div class="description mt-10">
                                    <span>Header</span>
                                    <json-viewer :value="requestHeader" :expand-depth=10 copyable
                                                 expanded></json-viewer>
                                </div>
                                <div class="description mt-10">
                                    <span>Form Data</span>
                                    <json-viewer :value="requestJsonData" :expand-depth=10 copyable
                                                 expanded></json-viewer>
                                </div>
                            </el-col>
                        </el-tab-pane>
                    </el-tabs>
                </div>
                <div class="mt-20">
                    <span>返回字段说明:</span>
                    <el-divider></el-divider>
                    <el-row class="mt-20">
                        <el-col :span="24">
                            <el-table
                                    :data="api.response_params"
                                    border
                                    style="width: 100%"
                                    row-key="_index"
                                    default-expand-all>
                                <el-table-column
                                        prop="key"
                                        label="字段名称"
                                        width="350">
                                </el-table-column>
                                <el-table-column
                                        prop="type"
                                        label="字段类型"
                                        width="180">
                                </el-table-column>
                                <el-table-column
                                        prop="description"
                                        label="字段说明">
                                </el-table-column>
                            </el-table>
                        </el-col>
                    </el-row>
                </div>
            </el-main>
        </el-container>
    </el-container>
</template>

<script>
    import JsonViewer from 'vue-json-viewer'
    import ImageUploader from '../components/ImageUploader.vue'
    import http from '../cloud/http'
    import Sign from '../utils/sign.js'


    export default {
        name: 'Api',
        mounted() {
            this.loadApi()
        },
        components: {
            JsonViewer, ImageUploader
        },
        data() {
            return {
                isLoading: false,
                projectId: this.$route.query.project || 0,
                project: {},
                modules: [],
                api: {
                    request_params: []
                },
                debug_url: '',
                debugParams: {},
                responseJsonData: "",
                requestJsonData: "",
                activeResultTab: 'request',
                activeParamsTab: 'biz',
                openeds: [],
                requestHeader: {}
            }
        },
        methods: {
            //刷新
            refresh() {
                this.loadApi()
            },
            //加载前页面操作
            beforeUpload() {

            },
            onChange() {

            },
            onProgress() {
                this.$loading.show('上传中')
            },
            onSuccess(result) {
                this.$loading.close();
                if (result.errCode) {
                    return this.$message.alert('文件上传失败:' + result.message)
                }
                this.$message.success('文件上传成功');
                this.responseJsonData = result
            },
            goBack() {

            },
            addProjectParams() {
                this.project.debug_setting.push({
                    key: '',
                    value: '',
                    temp: 1
                })
            },
            removeProjectParams(index) {
                this.project.debug_setting.splice(index, 1)
            },
            //动态增加调试参数
            addRequestParams() {
                this.api.request_params.push({
                    key: '',
                    description: '动态增加的参数',
                    type: 'String',
                    required: '0',
                    temp: 1
                })
            },
            removeRequestParams(index) {
                this.api.request_params.splice(index, 1)
            },
            /**
             * 调试参数初始化
             */
            debugParamsInit() {
                this.responseJsonData = "";
                this.debugParams = {};
                console.log(this.project.debug_setting);
                if (this.project.debug_setting.length) {
                    this.paramsTransform(this.project.debug_setting)
                }
            },
            /**
             * 调试请求参数转换
             * @param params
             * @returns {ElMessageComponent}
             */
            paramsTransform(params) {
                if (this.project.project_type == 2) {
                    if (!this.project.debug_appid) {
                        return this.$message.error('未设置项目调试appid')
                    }
                    if (!this.project.debug_appkey) {
                        return this.$message.error('未设置项目调试appkey')
                    }
                }
                let rand = Sign.rand();
                let timestamp = Sign.timestamp();
                params.forEach(item => {
                    if (item.value) {
                        this.$session.set('_api_debug_params_' + this.project.id + '_' + item.key, item.value)
                    }
                    if (item.value === '{appid}') {
                        this.debugParams[item.key] = this.project.debug_appid
                    } else if (item.value === '{rand}') {
                        this.debugParams[item.key] = rand
                    } else if (item.value == '{timestamp}') {
                        this.debugParams[item.key] = timestamp
                    } else if (item.value == '{token}') {
                        this.debugParams[item.key] = this.project.debug_token || ''
                    } else if (item.value == '{sign}') {
                        //sign=md5(appid+md5(appkey)+时间戳+随机字符串)
                        let sign = this.$md5(this.project.debug_appid + this.$md5(this.project.debug_appkey) + timestamp + rand);
                        this.debugParams[item.key] = sign
                    } else if (item.type == 'Object' && item.children) {
                        this.debugParams[item.key] = {};
                        item.children.forEach(children => {
                            this.debugParams[item.key][children.key] = children.value
                        })
                    } else if (item.type === 'Timestamp' && item.value && item.value.toString().length !== 10) {
                        this.debugParams[item.key] = item.value / 1000
                    } else {
                        this.debugParams[item.key] = item.value || ''
                    }
                })
            },
            //默认给调试参数赋值
            setDebugParamsValue() {
                if (this.api.request_params.length) {
                    let params = [];
                    this.api.request_params.forEach(item => {
                        if (item.key === 'appid') {
                            item.value = '{appid}'
                        } else if (item.key === 'rand') {
                            item.value = '{rand}'
                        } else if (item.key === 'timestamp') {
                            item.value = '{timestamp}'
                        } else if (item.key === 'token') {
                            item.value = '{token}'
                        } else if (item.key === 'sign') {
                            item.value = '{sign}'
                        } else {
                            item.value = '{' + item.key + '}';
                        }
                        params.push(item)
                    });
                    this.$set(this.api, 'request_params', params)
                }
            },
            //进行接口调试
            debug() {
                this.debugParamsInit();
                if (this.api.request_params.length) {
                    this.paramsTransform(this.api.request_params)
                }
                this.debug_url = this.api.url;
                let requestParams = this.debugParams;
                if (this.project.project_type == 1) {
                    this.debug_url = this.api.url
                } else {
                    //站外应用基础参数使用header传递
                    this.requestHeader = {
                        appid: requestParams.appid,
                        timestamp: requestParams.timestamp,
                        rand: requestParams.rand,
                        sign: requestParams.sign,
                        managePlatform: 2,
                    };
                    requestParams.token ? this.requestHeader.token = requestParams.token : '';
                    requestParams.mpappid ? this.requestHeader.mpappid = requestParams.mpappid : '';
                    requestParams.sessionkey ? this.requestHeader.sessionkey = requestParams.sessionkey : '';
                    requestParams.project ? this.requestHeader.project = requestParams.project : '';
                    delete requestParams.appid;
                    delete requestParams.timestamp;
                    delete requestParams.rand;
                    delete requestParams.sign;
                    delete requestParams.token;
                    delete requestParams.mpappid;
                    delete requestParams.sessionkey;
                    delete requestParams.project;
                }
                this.requestJsonData = requestParams;
                if (this.api.request_type == 1) {
                    this.isLoading = true;
                    http({
                        url: this.debug_url,
                        method: 'get',
                        params: requestParams,
                        headers: this.requestHeader
                    }).then(result => {
                        this.responseJsonData = result;
                    }).catch(error => {
                        this.$message.error(error)
                    }).finally(() => {
                        this.isLoading = false
                    })
                } else {
                    this.isLoading = true;
                    let data = requestParams;
                    http({
                        url: this.debug_url,
                        method: 'post',
                        headers: this.requestHeader,
                        data
                    }).then(result => {
                        this.responseJsonData = result
                    }).catch(error => {
                        this.$message.error(error)
                    }).finally(() => {
                        this.isLoading = false
                    })
                }
                //console.log(this.debugParams)
            },
            //加载api列表
            loadApi() {
                if (!this.projectId) {
                    return this.$message.error('非法访问,无效的项目')
                }
                this.$loading.show('加载中');
                this.$api.system.api('GetProjectsApi', {id: this.projectId}).then(result => {
                    if (result.errCode) {
                        return this.$message.error(result.message)
                    } else {
                        this.project = result.data.project;
                        this.modules = result.data.modules;
                        if (this.project.debug_setting.length) {
                            this.project.debug_setting.forEach(item => {
                                if (!item.value) {
                                    item.value = this.$session.get('_api_debug_params_' + this.project.id + '_' + item.key) || ''
                                }
                            })
                        }
                        if (!this.api.id) {
                            let openeds = [];
                            if (this.modules.length) {
                                this.modules.forEach((i, k) => {
                                    if (k == 0) {
                                        openeds.push('' + (k + 1) + '')
                                    }
                                });
                                this.openeds = openeds
                            }
                            this.detail(this.modules[0].api[0])
                        }
                    }
                }).catch(error => {
                    this.$message.error(error)
                }).finally(() => {
                    this.$loading.close();
                })
            },
            //此处的 api参数接收的是一个关于接口信息的对象
            detail(api) {
                this.api = this.$copy(api);
                this.requestJsonData = null;
                this.responseJsonData = null;
                //返回数据信息渲染
                this.setIndex(this.api.response_params);
                //请求信息数据渲染
                if (this.api.request_params) {
                    let params = this.api.request_params;
                    params.forEach((item, key) => {
                        let description = item.description;
                        //默认填充参数
                        let arr = description.split('=>');
                        this.$set(this.api.request_params[key], 'description', arr[0]);
                        this.$set(this.api.request_params[key], 'value', arr.length > 1 ? description.replace(arr[0] + "=>", "") : '');
                        if (item.type === 'Array' || item.type === 'ImageArray' || item.type === 'ImageUrlArray') {
                            this.$set(this.api.request_params[key], 'value', [arr[1] || '', arr[2] || ''])
                        }
                        if (item.children !== undefined) {
                            item.children.forEach((children, ckey) => {
                                let description = children.description;
                                let arr = description.split('=>');
                                this.$set(this.api.request_params[key].children[ckey], 'description', arr[0])
                                this.$set(this.api.request_params[key].children[ckey], 'value', arr.length > 1 ? description.replace(arr[0] + "=>", "") : '')
                                if (children.type == 'Array') {
                                    this.$set(this.api.request_params[key].children[ckey], 'value', [arr[1] || '', arr[2] || ''])
                                }
                            })
                        }
                    })
                }
                this.$nextTick(() => {
                    this.scrollTop(document.querySelector(".el-main").scrollTop)
                });
            },
            scrollTop(top) {
                setTimeout(() => {
                    document.querySelector(".el-main").scrollTop = top;
                    top = top - 20
                    if (top > 0) {
                        this.scrollTop(top)
                    } else if (top < 0) {
                        document.querySelector(".el-main").scrollTop = 0;
                    }
                }, 1)
            },
            setIndex(params) {
                params.forEach(item => {
                    item._index = Sign.rand();
                    if (item.children) {
                        this.setIndex(item.children)
                    }
                })
            }
        },
    }
</script>
<style>
    #app {
        width: 100%;
        height: 100%;

    }

    .api-header {
        text-align: center;
        padding-top: 10px;
        border-bottom: 1px solid #ebebeb;
        border-radius: 3px;
        background-color: #F8F8F8;
    }

    .api-aside {
        padding-left: 50px;
    }

    .description {
        padding: 20px;
        box-sizing: border-box;
        border: 1px solid #ebebeb;
        border-radius: 3px;
        font-size: 14px;
        line-height: 22px;
        color: #666;
        word-break: break-word;
        margin: 10px;
        background-color: #F8F8F8;
    }

    .jv-container, .jv-code {
        background-color: #F8F8F8;
    }
</style>