<template>
    <el-upload
            style="width: 50px"
            action="/common/upload/multipart/"
            :show-file-list="false"
            :on-progress="onProgress"
            :on-change="onChange"
            :on-success="onSuccess"
            :before-upload="beforeUpload">
        <el-popover
                v-if="fileUrl"
                placement="right-start"
                :title="fileName"
                width="500"
                trigger="hover">
            <a :href="fileUrl" target="_blank"><img :src="fileUrl" width="500"></a>
            <img slot="reference" :src="fileUrl" width="30" height="30">
        </el-popover>
        <el-col v-else :space="4">
            <el-button slot="default" type="primary" size="small" :disabled="isUploading">上传<i :class="{'el-icon-upload2 el-icon--right':!isUploading,'el-icon-loading el-icon--right':isUploading}"></i></el-button>
        </el-col>
    </el-upload>
</template>

<script>
    import http from '../cloud/http'

    export default {
        name: "ImageUploader",
        props: {
            value: {
                type: String
            },
            //数据类型 id/url
            dt: {
                type: String,
                default: 'url'
            }
        },
        mounted() {
            this.loadFile()
        },
        data() {
            return {
                isUploading: false,
                file: this.value,
                fileUrl: '',
                fileName: ''
            }
        },
        watch: {
            //监听prop传的value，如果父级有变化了，将子组件的myValue也跟着变，达到父变子变的效果
            value(v) {
                this.file = v;
                this.loadFile()
            },
            file(v) {
                this.$emit('input', v)
            }
        },
        methods: {
            loadFile() {
                this.fileUrl = '';
                this.fileName = '';
                if (!this.file) {
                    return
                }
                this.fileName = '';
                this.fileUrl = this.file;
                /*
                if (this.dt == 'id') {
                    this.isUploading = true;
                    http({
                        url: '/common/file/get/' + this.file + '/',
                        method: 'get',
                        params: {id: this.file}
                    }).then(result => {
                        console.log(result);
                        this.fileName = result.data.original_name;
                        this.fileUrl = result.data.object_url
                    }).catch(error => {
                        console.log('文件加载失败:' + error)
                    }).finally(() => {
                        this.isUploading = false
                    })
                } else {
                    this.fileName = '';
                    this.fileUrl = this.file
                }
                */
            },
            beforeUpload(file) {
                const isJPG = file.type === 'image/jpeg';
                const isPNG = file.type === 'image/png';
                const isGif = file.type === 'image/gif';
                const isLt2M = file.size / 1024 / 1024 < 2;
                if (!isJPG && !isPNG && !isGif) {
                    return this.$message.error('上传头像图片只能是 JPG/png/gif 格式!');
                }
                if (!isLt2M) {
                    return this.$message.error('上传头像图片大小不能超过 2MB!');
                }
                return true;
            },
            onChange() {
                this.isUploading = false
            },
            onProgress() {
                this.fileUrl = '';
                this.isUploading = true
            },
            onSuccess(result) {
                if (result.errCode) {
                    return this.$message.error(result.message)
                }
                this.fileUrl = result.data.object_url;
                this.fileName = result.data.original_name;
                this.file = this.dt == 'id' ? result.data.file : result.data.object_url
            },
        }
    }
</script>

<style scoped>

</style>